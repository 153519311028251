* {
  font-family: 'Open Sans', sans-serif;
}

.MoonView-title {
  font-size: 1.5em;
}

.Pump-Button {
  background: #e91e63;
  box-shadow: -1px 2px 7px 0 #e91e63;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  color: white;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MoonView {
  display: grid;
  height: 100%;
  justify-content: center;
  align-content: center;
  background-image: linear-gradient(
    163deg,
    #5590f0 5%,
    #5095e4 20%,
    #5997dd 60%,
    #352e98 95%
  );

  padding: 10px;
  margin-top: -30px;
}

.MoonView-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.MoonView-container {
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 550px;
}

.MoonView-content-container {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  padding: 5px;
  margin: 5px;
}

.MoonView-main-container {
  display: grid;
  grid-template-rows: 0.8fr 1fr;
  min-width: 300px;
}

.MoonView-main-section {
  display: grid;
  grid-template-rows: 1fr 55px;
}

.MoonView-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.MoonView-header {
  padding: 8px;
  text-align: center;
}

.MoonView-hodl-container {
  display: flex;
}

.MoonView-header-hodl-number {
  text-align: center;
  font-size: larger;
}

.MoonView-hodl-label {
  margin-top: 10px;
  font-size: 1.2em;
}

.MoonView-pricing-label,
.MoonView-pricing-now {
  color: white;
}

.MoonView-pricing-header-row {
  color: white;
  justify-items: center;
}

.MoonView-projection {
  font-weight: bold;
}

.MoonView-pricing-row {
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr;
  align-items: center;
}

/* ================================ */
/* Rocket container styling */
/* ================================ */

.MoonView-rocket-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  max-width: 50px;
}

.MoonView-rocket-item {
  font-size: 2em;
  z-index: 1;
  cursor: pointer;
}

.MoonView-rocket-trajectory {
  height: 100%;
  margin-top: -5px;
  margin-bottom: -15px;
  padding: 0px;
  background: #ffffff;
  box-shadow: -1px 2px 7px 0 #d8d8d8;
}

#rocket {
  position: absolute;
  bottom: 0%;
  transform: rotate(315deg);
  transition: 0.8s cubic-bezier(0.37, 1.3, 0.71, 0.96);
  z-index: 2;
}

/* ====== End of Rocket Container styling ========  */

/* Pump styling */

.MoonView-pump-container {
  display: flex;
  position: relative;
}

.MoonView-pump-symbol {
  position: absolute;
  z-index: 2;
  margin-top: -2px;
  margin-left: 35px;
}

.MoonView-pump-button {
  width: 100%;
  margin-left: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

/* ===========Bubbles css animation================ */
.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
}
.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 10s infinite ease-in;
}
.bubble:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 8s;
}
.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  left: 20%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 7s;
  animation-delay: 2s;
}
.bubble:nth-child(4) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 11s;
  animation-delay: 0s;
}
.bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: 1s;
}
.bubble:nth-child(6) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: 3s;
}
.bubble:nth-child(7) {
  width: 90px;
  height: 90px;
  left: 70%;
  animation-duration: 12s;
  animation-delay: 2s;
}
.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 6s;
  animation-delay: 2s;
}
.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(10) {
  width: 90px;
  height: 90px;
  left: 25%;
  animation-duration: 10s;
  animation-delay: 4s;
}
@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}

/* ====== End of Bubbles styling ========  */
